// Here you can add other styles
.hide-number-arrows {
  -moz-appearance: textfield; /* Firefox */
}

.hide-number-arrows::-webkit-inner-spin-button,
.hide-number-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Webkit browsers */
  margin: 0; /* Removes the default margin */
}

.MuiTablePagination-root p {
  margin-bottom: 0;
}
